import { css } from 'styled-components';

export const removeSideSpacing = css`
  margin-left: calc(var(--section-side-padding) * -1) !important;
  margin-right: calc(var(--section-side-padding) * -1) !important;
`

export const makeFullWidth = css`
  // https://css-tricks.com/full-width-containers-limited-width-parents/#no-calc-needed
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

export const gap = css`var(--gutter)`

export const cssSpacing = (size = 2) => {
  return css`
    margin-bottom: calc(var(--section-spacing) * ${size / 4});
  `
}
