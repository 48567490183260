import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { ImageWithTextProps } from "../../components/organisms/ImageWithText"
import CalculatorWithTextSection from "../../components/sections/CalculatorWithTextSection"
import { normalizeImageProps } from "../../utils/image"

export default ({ primary, items }) => {
  const {
    button_text,
    button_url,
    heading,
    calc_position,
    text,
  } = primary
  return (
    <CalculatorWithTextSection
      heading={parse(heading?.html)}
      text={parse(text?.html)}
      buttonText={button_text}
      buttonUrl={button_url}
      calcPosition={calc_position}
    />
  )
}

export const query = graphql`
    fragment CalculatorWithText on PrismicPageDataBodyCalculatorWithText {
        slice_label
        slice_type
        __typename
        id
        primary{
            button_text
            button_url {
                url
                target
            }
            heading {
                html
            }
            calc_position
            text {
                html
            }
        }
    }
`
