import * as React from "react"
import Container from "../../atoms/Container"
import ContactInfo, { ContactInfoProps } from "../../molecules/ContactInfo"
import styled from "styled-components"

const StyledContactContainer = styled(Container)`
  & + &{
    margin-top: calc(var(--section-spacing) / -2.5);
  }
`

const StyledText = styled.div`
  margin-bottom: 28px;
  text-align: center;
  
  h2{
    margin-block-end: calc(var(--heading-margin-block-end) / 2);
  }
`

const ContactInfoSection = (props: ContactInfoProps) => (
  <StyledContactContainer>
    <StyledText>
      <h2>{props.heading}</h2>
      {props.hours}
    </StyledText>
    <ContactInfo {...props} />
  </StyledContactContainer>
)

export default ContactInfoSection
