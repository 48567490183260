import * as React from "react"
import { graphql } from "gatsby"
import Text from '../../components/sections/TextSection';
import parse from 'html-react-parser'

export default ({primary}) => {
  const {button_url = {}, button_text = "", text = {}, style = 'normal', alignment, bottom_margin = "default"} = primary
  return (
    <Text
      alignment={alignment}  
      buttonUrl={button_url} 
      buttonText={button_text} 
      className={style.toLowerCase()}
      bottomMargin={bottom_margin}
    >
      {parse(text?.html)}
    </Text>
  )
}

export const query = graphql`
    fragment Text on PrismicPageDataBodyText{
        slice_type
        slice_label
        __typename 
        primary{
            text{
                html
            }
            button_url{
                url
                target
            }
            button_text
            style
            alignment
            bottom_margin
        }
    }
`
