import React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import Button from "../../atoms/Button"

const StyledContainer = styled(Container)`
  //this allows the text block to be the first container
  // and not appear too far away from the header
  &:first-child > div{
    margin-top: calc(var(--section-spacing) / 2);
  }
  &.callout p {
    font-size: var(--callout-text-size);
  }
`

const TextSection = ({
                       alignment = "center",
                       children = null,
                       className = "",
                       buttonText = "",
                       buttonUrl = {},
                       bottomMargin = "default"
                     }) => {
  const buttonType = {
    secondary: className !== "callout",
    primary: className === "callout"
  }

  let bottomMarginMultiplier
  switch (bottomMargin) {
    case "xs":
      bottomMarginMultiplier = 4
      break
    case "sm":
      bottomMarginMultiplier = 2
      break
    case "default":
    default:
      bottomMarginMultiplier = 0
      break
  }

  const spacingProps = {
    containerProps: {
      style: {
        marginBottom: bottomMarginMultiplier ? `calc(calc(var(--section-spacing) / ${bottomMarginMultiplier}) - var(--section-spacing)) ` : 0
      }
    }
  }

  return (
    <StyledContainer 
      className={className} 
      small 
      {...spacingProps} 
      innerProps={{ style: { textAlign: alignment } }}
    >
      {children}
      {buttonText && buttonUrl &&
      <Button to={buttonUrl?.url} target={buttonUrl?.target} {...buttonType}>{buttonText}</Button>
      }
    </StyledContainer>
  )
}

export default TextSection
