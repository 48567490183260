import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"

interface ImageProps {
  style?: React.CSSProperties,
  className?: string,
  showOffset?: boolean,
  direction?: "left" | "right",
  gatsbyImageProps: GatsbyImageProps,
}

const StyledImage = styled.div`
  ${({ showShadow, theme }) => showShadow && theme.shadow}
  overflow: visible;
`

const Image = ({
                 style = {},
                 className = "",
                 gatsbyImageProps = null
               }: ImageProps) => {

  return (
    <div style={{ position: "relative", overflow: "visible", ...style }} className={className}>
      <StyledImage>
        <GatsbyImage {...gatsbyImageProps} />
      </StyledImage>
    </div>
  )
}

export default Image
