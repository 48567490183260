import * as React from 'react';
import styled from 'styled-components';
import {GatsbyImage, GatsbyImageProps} from 'gatsby-plugin-image';
import {CSSProperties} from "react";

type BackgroundProps = {
    style?: CSSProperties,
    children?: React.ReactNode,
    className?: string,
    overlay?: string,
    color?: string,
    image?: GatsbyImageProps,
}

const StyledBackground = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const StyledOverlay = styled.div`
  position: absolute; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({color}) => color};
  cursor: pointer;
`

const Background = (
    {
        style = {},
        children = null,
        className = '',
        overlay = '',
        color = '',
        image = null
    }: BackgroundProps
) => (
    <StyledBackground className={className} style={{backgroundColor: color || 'transparent', ...style}}>
        {image?.image &&
        <GatsbyImage {...image} />
        }
        {children}
        {overlay && <StyledOverlay color={overlay}/>}
    </StyledBackground>
);

export default Background;
