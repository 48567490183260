import * as React from "react"
import { graphql } from "gatsby"
import Container from "../../components/atoms/Container"
import parse from "html-react-parser"

export default ({ primary }) => {
  const { heading = {}, alignment = 'left' } = primary
  return (
    <Container innerProps={{ marginBottom: "calc(3rem + calc(var(--section-spacing) * -1))", style: {textAlign: alignment} }}>
      {parse(heading?.html)}
    </Container>
  )
}

export const query = graphql`
    fragment Heading on PrismicPageDataBodyHeading{
        slice_type
        slice_label
        __typename 
        primary{
            heading{
                html
            }
            alignment
        }
    }
`
