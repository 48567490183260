import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import { query } from "atomic-layout"

const StyledQuestion = styled.div`
  border-bottom: 1px solid #E5E1E6;
  padding: 24px 0;
  
  button{
    text-align: left;
  }
  
  .question{
    font-size: 18px;
    font-weight: bold;
    line-height: 140%;
    display: flex;
    cursor: pointer;
    width: 100%;
    background: none;
    border: none;
    color: var(--body-text-color);
    @media ${query({from: "lg"})}{
        font-size: 24px;
    }
    
    &:after{
      content: "${({isOpen}) => isOpen ? '-' : '+'}";
      font-weight: normal;
      align-self: flex-start;
      margin-left: auto;
      padding-left: 15px;
    }
  }

  .answer{
     display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  }
`

const Question = ({title, text, index}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <StyledQuestion key={title} isOpen={isOpen}>
      <button
        id={"accordion-header-" + index}
        className={"question"} 
        aria-expanded={isOpen} 
        onClick={() => setIsOpen(!isOpen)}
        aria-controls={"accordion-panel-" + index}
        style={{padding:0}}
      >
        {title}
      </button>
      <div 
        className={"answer"} 
        id={"accordion-panel-" + index} 
        aria-labelledby={"accordion-header-" + index}
        hidden={!isOpen}
      >
        {text}
      </div>
    </StyledQuestion>
  )
}

export default Question
