import * as React from "react"
import { graphql } from "gatsby"
import TestimonialSection from "../../components/sections/TestimonialSection"
import parse from "html-react-parser"

export default ({ items }) => {
  const testimonials = items.map(({text, name, person_title}) => {
    return {
      text: parse(text?.html),
      name,
      title: person_title
    }
  })
  
  return (
    <TestimonialSection testimonials={testimonials} />
  )
}

export const query = graphql`
    fragment Testimonials on PrismicPageDataBodyTestimonials{
        slice_type
        slice_label
        __typename 
        items{
            text{
                html
            }
            name
            person_title
        }
    }
`
