import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import { ReactNode } from "react"
import Button from "../components/atoms/Button"
import Navigation from "../components/organisms/Navigation"

export function PrismicNavigation({ lang = "" }) {
  function createLink(linkData: any, isActive: boolean = false, hasSub: boolean = false): ReactNode {
    const { label, link, style } = linkData
    const isLink = style !== 'Button';

    return (
      <Button
        href={link.url}
        isLink={isLink}
        secondary={!isLink}
        className={[!isLink ? 'btn' : '', isActive ? 'active' : '', hasSub ? 'caret' : '']}
      >
        {label}
      </Button>
    )
  }

  return (
    <StaticQuery
      query={`${query}`}
      render={({ prismicMainMenu }) => {
        const { data } = prismicMainMenu

        const links = data.body.reduce((prev, menuItem: any, index: number) => {
          const { primary, items } = menuItem

          let isActive = false;
          if(typeof window !== 'undefined'){
            const {link: {url}} = primary;
            const currentPath = window.location.pathname;
            if(currentPath === '/' || url === '/'){
              isActive = currentPath === url
            } else {
              isActive = currentPath.startsWith(url);
            }
          }

          let menu = {
            link: createLink(primary, isActive, !!items.length),
            sub: items.map((link: any) => {
              return { link: createLink(link), sub: [] } // these menus only go one level deep
            })
          }

          prev.push(menu)
          return prev
        }, [])

        return <Navigation menuItems={links} />
      }}
    />)
}

export const query = graphql`
    query navQuery{
        prismicMainMenu {
            data {
                body {
                    ... on PrismicMainMenuDataBodyMenuItem{
                        items{
                            label
                            link {
                                lang
                                link_type
                                isBroken
                                url
                            }
                        }
                        primary {
                            label
                            style
                            link {
                                lang
                                link_type
                                isBroken
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
