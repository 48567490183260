import { Box } from "atomic-layout"
import * as React from "react"
import styled from "styled-components"
import Button from "../../atoms/Button"
import Container from "../../atoms/Container"
import {query} from "atomic-layout"

const StyledCallout = styled(Box)`
  padding: 60px 2rem;
  color: #fff;
  background-color: #003057;
  border-radius: var(--roundness);
  text-align: center;
  @media ${query({from: "lg"})}{
    padding: 80px 2rem;
  }
  
  .callout-text{
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--paragraph-margin-block-end);
  }
`

const Callout = ({
                   heading = null,
                   text = null,
                   primaryButtonText = "",
                   primaryButtonUrl = {},
                   secondaryButtonText = "",
                   secondaryButtonUrl = {}
                 }) =>
  (
    <Container>
      <StyledCallout>
        {heading}
        
        <div className={'callout-text'}>
          {text}  
        </div>
        
        {primaryButtonText &&
        <Button to={primaryButtonUrl?.url} target={primaryButtonUrl?.target} outline>{primaryButtonText}</Button>
        }
        {secondaryButtonText &&
        <Button to={secondaryButtonUrl?.url} target={secondaryButtonUrl?.target} secondary style={{
          fontSize: 18,
          whiteSpace: "nowrap"
        }}>{secondaryButtonText}</Button>
        }
      </StyledCallout>
    </Container>
  )

export default Callout
