import * as React from "react"
import { graphql } from "gatsby"
import MaxLoanSection from "../../components/sections/MaxLoanSection"

export default ({ primary }) => {

  return (
    <MaxLoanSection />
  )
}

export const query = graphql`
    fragment MaxLoan on PrismicPageDataBodyMaxLoanCalculator {
        slice_label
        slice_type
        __typename
    }
`
