import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import StatsSection from "../../components/sections/StatsSection"

export default ({ items, primary }) => <StatsSection
  items={
    items.map(({ heading, plain_text }) => (
      { heading: parse(heading?.html), text: plain_text }
    ))}
  mutedColors={primary?.muted_colors}
/>

export const query = graphql`
    fragment Stats on PrismicPageDataBodyStats {
        slice_label
        slice_type
        __typename
        id
        primary{
            muted_colors
        }
        items {
            heading{
                html
            }
            plain_text
        }
    }
`
