import * as React from "react"
import styled from "styled-components"
import { query } from "atomic-layout"

export interface TestimonialProps {
  name?: string,
  title?: string,
  text?: React.ReactNode
}

const StyledTestimonialInner = styled.div`
  
`
const StyledTestimonial = styled.div`
  text-align: left;
  width: 80%;
  min-width: 80%;
  ${({theme}) => theme.shadow}
  background: #FFFFFF;
  border: 1px solid #C69214;
  box-sizing: border-box;
  border-radius: 22px;
  padding: 40px;
  margin-top: 69px;
  margin-bottom: 108px;
  
  margin-right: 5%;
  &:first-child{
    margin-left: 5%;
  }
  &:last-child{
    padding-right: 5%;
  }

  @media ${query({ from: "lg" })} {
    padding: 80px;
    width: 580px;
    min-width: 580px;
    margin-right: 30px;
    &:first-child{
      margin-left: 92px;
    }
    &:last-child{
      margin-right: 92px;
    }
  }
`

const StyledText = styled.div`
  margin-bottom: 40px;
  
  p{
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #4E4B48;
    margin: 0;
    @media ${query({ from: "md" })} {
      font-size: 24px;
    }
    
    &:before{
      content: "“";
    }

    &:after{
      content: "”";
    }
  }
  
`

const StyledName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  letter-spacing: -0.02em;
  color: #003057;
`

const StyledTitle = styled.div`
  font-size: 18px;
  line-height: 155%;
  color: #B2AAAB;
`

const Testimonial = ({text = null, title = "", name = ""}: TestimonialProps) => (
  <StyledTestimonial>
      <StyledText>{text}</StyledText>
      <StyledName>{name}</StyledName>
      <StyledTitle>{title}</StyledTitle>
  </StyledTestimonial>
)

export default Testimonial
