import * as React from "react"
import Layout from "../layout"
import SliceRenderer from "../slices"
import { PrismicNavigation } from "../layout/PrismicNavigation"
import PrismicFooter from "../layout/PrismicFooter"
import parse from "html-react-parser"
import Container from "../components/atoms/Container"
import { Helmet } from "react-helmet"

const PageDefault = (page) => {
  const { show_title_and_intro_text, title, intro_text, disclaimer_text } = page?.data?.prismicPage?.data

  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="https://assets.drivercapital.ca/maxloan-calculator/drivercapital.css" />
      </Helmet>
      <PrismicNavigation />
      {show_title_and_intro_text &&
        <Container removeVerticalSpacing containerProps={{
          style: {
            textAlign: "center",
            marginTop: "calc(var(--section-spacing) * 0.6667)",
            marginBottom: "calc(var(--section-spacing) / -2)"
          }
        }}>
          <h1>{title}</h1>
          {parse(intro_text?.html)}
        </Container>
      }
      <SliceRenderer doc={page?.data?.prismicPage} />

      <PrismicFooter disclaimer={parse(disclaimer_text?.html)} />
    </Layout>
  )

}

export default PageDefault
