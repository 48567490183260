import * as React from "react"
import { graphql } from "gatsby"
import Hero from "../../components/sections/HeroSection"
import parse from "html-react-parser"
import { normalizeImageProps } from "../../utils/image"

export default ({ primary }) => {
  const {
    button_text = null,
    button_url = null,
    heading = {},
    text = {},
    image = {},
  } = primary

  return (
    <Hero
      heading={parse(heading?.html)}
      text={parse(text?.html)}
      buttonText={button_text}
      buttonUrl={button_url}
      image={normalizeImageProps(image)}
    />
  )
}

export const query = graphql`
    fragment Hero on PrismicPageDataBodyHero{
        slice_type
        slice_label
        __typename 
        __typename
        primary{
            button_text
            button_url {
                url
                target
            }
            text{
                html
                text
            }
            heading{
                html
                text
            }
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`
