import * as React from "react"
import Helmet from "react-helmet"
import { graphql, StaticQuery } from "gatsby"
import { PrismicNavigation } from "../layout/PrismicNavigation"
import { getSlice } from "./mappings"

interface Component {
  type: string,
    primary?: object,
    items?: object,
    __typename: string,
}

interface SliceRendererProps {
  node: {
    meta_title: string,
      meta_description: string,
      title: string,
      _meta: any,
      body: Component[],
      __typename: string,
  }
}

const SliceRenderer = ({ doc, children }) => {
  const { meta_title, meta_description, title, body: components } = doc.data

  const query = graphql`
    query SitePages{
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
    }
  `
  return <StaticQuery
    query={`${query}`}
    render={data => {
      let helmetProps = { title: meta_title ? meta_title : title }
      if (meta_title) {
        helmetProps["titleTemplate"] = ""
      }

      return (
        <React.Fragment>
          
          <Helmet {...helmetProps}>
            {meta_description &&
            <meta name="description" content={meta_description}/>
            }
          </Helmet>

          {children}
          
          <main>
          {components && components.map((comp, index) => {

            const { items = {}, primary = {}, __typename } = comp
            // Slices should always have a 'primary' or 'items' key with it's associated data.
            if (!items && !primary && process.env.NODE_ENV === "development") {
              console.warn("No inline fragment has been provided for " + __typename)
              return null
            }

            const mappedComponent = getSlice(__typename)

            // If we're in development, show an error that the component cannot be found
            if (!mappedComponent && process.env.NODE_ENV === "development") {
              console.warn("Component not found: The body renderer could not find a mapping for \"" + __typename + "\". Please add it to mappings.tsx")

              return <div style={{fontSize: '16px', padding: '20px', textAlign: 'center', backgroundColor: '#fff8b4'}}>Could not find {__typename}</div>
            }

            return React.createElement(mappedComponent, {
              key: "slice" + index,
              ...comp
            })
          })}
          </main>
        </React.Fragment>
      )
    }}
  />
}

export default SliceRenderer
