import * as React from "react"
import { useEffect, useRef } from "react"

function getQueryVariable(variable)
{
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] == variable){return pair[1];}
  }
  return(null);
}

const MaxLoan = (props) => {
  if(typeof window !== "undefined"){
    const key = getQueryVariable('key');
    
    const ref = useRef(null)
    useEffect(() => {
      ref.current.setAttribute('key', key);

    }, [ref, key])
    
    return <maxloan-calculator
      ref={ref}
      style={{
        marginLeft: "calc(var(--section-side-padding) / -1.5)",
        marginRight: "calc(var(--section-side-padding) / -1.5)",
        display: "block"
      }}
    />
  }
  
  return null;
}

export default MaxLoan
