import * as React from "react"
import { useEffect, useState } from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import { query } from "atomic-layout"
import Button  from "../../atoms/Button"

const StyledResults = styled.div`
  background: #003057;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  padding: 36px 16px;
  color: #fff;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(36px - 80px);
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${query({from: "md"})}{
    width: 80%;
    padding: 42px;
  }

  h2 {
    color: #C69214;
    font-size: 2rem;
  }

  .results-text {
    display: flex;
    flex-direction: column;

    .large-text {
      font-weight: bold;
      font-size: 2rem;
      line-height: 110%;
      color: #FFFFFF;
    }

    .small-text {
      text-align: center;
      color: rgba(255, 255, 255, 0.35);
      font-weight: normal;
      font-size: 23.99px;
    }

    .separator {
      display: none;
      margin: 0 32px;
      color: rgba(255, 255, 255, 0.15);
    }
    
    @media ${query({from: "md"})}{
      flex-direction: row;
      .separator {
        display: block;
      }
    }
    
    @media ${query({from: "lg"})}{
      .large-text {
        font-size: 50px;
      }
      .small-text {
        text-align: right;
      }
    }
    @media ${query({from: "xl"})}{
      .large-text {
        font-size: 65px;
      }
    }
  }
`

const StyledCurrentResults = styled.div`
  font-size: 24px;
  font-weight: bold;

  .amount {
    font-size: 31.98px;
    line-height: 130%;
    color: #003057;
    font-weight: bold;
  }

  .timeframe {
    font-weight: normal;
    font-size: 18px;
    line-height: 145%;
    color: #4E4B48;
  }

  .divider {
    font-weight: bold;
    font-size: 42.63px;
    line-height: 120%;
    color: #EFE0BC;
  }
`

const StyledCalculated = styled.div`
  background: #F9F4E8;
  border: 2px solid #EFE0BC;
  border-radius: 32px;
  padding: 36px 16px;
  padding-bottom: 80px;
  text-align: center;
  
  @media ${query({from: "md"})}{
      padding-left: 36px;
      padding-right: 36px;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  border-bottom: 1px solid #EFE0BC;
  margin-bottom: 32px;
  
  @media ${query({from: "lg"})}{
      flex-direction: row;
  }
`

const StyledMultiplier = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  
  @media ${query({from: "lg"})}{
    margin: 0 32px;
  }
`

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  @media ${query({from: "lg"})}{
    flex-direction: row;
  }

  label {
    font-size: 1rem;
    margin-bottom: 0;
    margin-left: 16px;
    position: relative;
  }

  div {
    position: relative;
  }

  .money:before {
    content: "$";
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .percent:before {
    content: "%";
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  input:not([type='submit']):not([type='checkbox']) {
    background: #EFE0BC;
    border: 1px solid transparent;
    width: auto;
    text-align: center;
  }

`

const StyledDisclaimer = styled.small`
  width: 100%;
  display: block;
  margin: 0 auto;
  @media ${query({from: "md"})}{
    width: 80%;
  }
`

const ROICalculatorSection = ({buttonUrl = null}) => {
  const defaultWritten = 70;
  const defaultSize = 3000;
  const defaultApproved = 50;
  const factor = 0.30

  const [written, setWritten] = useState(defaultWritten)
  const [size, setSize] = useState(defaultSize)
  const [approved, setApproved] = useState(defaultApproved)
  const [monthly, setMonthly] = useState(0)
  const [yearly, setYearly] = useState(0)
  const [currentMonthly, setCurrentMonthly] = useState(0)
  const [currentYearly, setCurrentYearly] = useState(0)
  const [sentGTMEvent, setSentGTMEvent] = useState(false);

  useEffect(() => {
    const approvalRate = Math.min(1, approved / 100)
    const increasedApprovalRate = Math.min(1, approvalRate + factor)

    const current = Math.round(written * size * approvalRate)
    const increased = Math.round(written * size * increasedApprovalRate)

    setMonthly(increased)
    setYearly(increased * 12)

    setCurrentMonthly(current)
    setCurrentYearly(current * 12)
    
    if(!sentGTMEvent){
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   'event': 'new_subscriber',
      //   'formLocation': 'footer'
      // });
      // setSentGTMEvent(true);
    }
  }, [written, size, approved])
  
  const setValue = (func, value, defaultValue) => {
    if(!sentGTMEvent && typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'roi_calc_used',
      });
      setSentGTMEvent(true);
    }
    
    const intVal = parseInt(value)
    if(isNaN(intVal)){
      func(defaultValue)
    } else {
      func(intVal)
    }
  }


  return (
    <Container innerProps={{style: {textAlign: "center"}}}>
      <a id={'roi-calculator'} className={'anchor'} />
      <StyledCalculated>
        <h3>Your shop’s work orders</h3>
        <StyledForm>
          <StyledInputGroup>
            <div>
              <input id={"written"} type={"number"} name={"written"} style={{ width: 80 }} placeholder={defaultWritten.toString(10)} defaultValue={defaultWritten}
                     onChange={(e) => setValue(setWritten, e.target.value, defaultWritten)} />
            </div>
            <label htmlFor={"written"}>Written in a month</label>
          </StyledInputGroup>

          <StyledMultiplier>x</StyledMultiplier>

          <StyledInputGroup>
            <div className={"money"}>
              <input id={"size"} type={"number"} name={"size"} style={{ width: 120 }} placeholder={defaultSize.toString(10)} defaultValue={defaultSize}
                     onChange={(e) => setValue(setSize, e.target.value, defaultSize)} />
            </div>
            <label htmlFor={"size"}>Average Size</label>
          </StyledInputGroup>

          <StyledMultiplier>x</StyledMultiplier>

          <StyledInputGroup>
            <div className={"percent"}>
              <input id={"approved"} type={"number"} name={"approved"} style={{ width: 120 }} placeholder={defaultApproved.toString(10)} defaultValue={defaultApproved}
                     onChange={(e) => setValue(setApproved, e.target.value, defaultApproved)} />
            </div>
            <label htmlFor={"approved"}>Approved by customers</label>
          </StyledInputGroup>
        </StyledForm>
        <StyledCurrentResults>
          Your current work order revenue = <span className={"amount"}>${currentMonthly.toLocaleString()}</span> <span
          className={"timeframe"}>Monthly</span> <span className={"divider"}>/</span> <span
          className={"amount"}>${currentYearly.toLocaleString()}</span> <span className={"timeframe"}>Yearly</span>
        </StyledCurrentResults>
      </StyledCalculated>
      <StyledResults>
        <h2>Revenue using Driver Capital</h2>
        <div className={"results-text"}>
          <div className={"large-text"}>
            ${monthly.toLocaleString()}
            <br />
            <h4 className={"small-text"}>Monthly</h4>
          </div>
          <div className={"separator large-text"}>/</div>
          <div className={"large-text"}>
            ${yearly.toLocaleString()}
            <br />
            <h4 className={"small-text"}>Yearly</h4>
          </div>
        </div>
      </StyledResults>
      <div style={{marginBottom: 40, textAlign: "center"}}>
        <StyledDisclaimer>The numbers in this calculator are for sample purposes only. The numbers above assume that half of the quotes written in a month are not fulfilled due to poor credit and reflect the amount you could be capturing if you fulfilled 50% of the work orders that don’t get processed.</StyledDisclaimer>
      </div>
      {buttonUrl?.url &&
        <Button to={buttonUrl?.url} target={buttonUrl?.target} primary>Grow Your Business Now</Button>
      }
    </Container>
  )
}

export default ROICalculatorSection
