import "pure-react-carousel/dist/react-carousel.es.css"
import * as React from "react"
import styled from "styled-components"
import Container from "../../atoms/Container"
import Testimonial, { TestimonialProps } from "../../organisms/Testimonial"
import { useResponsiveValue } from "atomic-layout"
import ScrollContainer from 'react-indiana-drag-scroll'

interface TestimonialSectionProps {
  testimonials: TestimonialProps[]
}

const StyledContainer = styled(Container)`
  //overflow-x: scroll;
`

const StyledSlider = styled(ScrollContainer)`
  display: flex;
  cursor: grab;
  overflow: auto!important;
  //overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  
  &.dragging{
    cursor: grabbing;
  }
  

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &:after {
    // this fixes safari butting the last testimonial against the right side.
    content: '';
    padding-right: 0.02px; /* smallest size that is cross browser */
  }
`

const StyledHeading = styled.div`
  text-align: center;
  margin-bottom: 0;
`
const TestimonialSection = ({ testimonials }: TestimonialSectionProps) => {

  const marginBottom = useResponsiveValue(
    {
      
    },
    "-110px"
  )

  return <StyledContainer fluid containerProps={{style: { marginBottom }}}>
    <StyledSlider vertical={false} draggingClassName={'dragging'}>
      {testimonials.map(({ text, name, title }, index) =>
        <Testimonial key={name + title + index.toString()} name={name} text={text} title={title} />
      )}
    </StyledSlider>
  </StyledContainer>
}

export default TestimonialSection
