import * as React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import MaxLoan from "../../organisms/MaxLoan"

const StyledHeading = styled.div`
  margin-bottom: 48px;
  text-align: center;
`

const MaxLoanSection = (props) => {
  return (
    <Container containerProps>
      <a className={"anchor"} id={"maxloan"} />
      <StyledHeading>
        <h2>Calculate your guaranteed Max Loan</h2>
        <p>Get the funds you need and back in the driver’s seat today.</p>
      </StyledHeading>
      <MaxLoan />
    </Container>
  )
}

export default MaxLoanSection
