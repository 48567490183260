import * as React from "react"
import { useEffect, useState } from "react"
import HubspotForm from "react-hubspot-form"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import Container from "../../atoms/Container"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"

const StyledRecaptcha = styled.div`
  font-size: 12px;
  color: #bdbdbd;
  margin-top: 2rem;
  text-align: center;

  a, a:hover {
    color: #bdbdbd;
    text-decoration: underline;
  }
`

const FormStyles = `
  ul.multi-container {
    list-style: none;
    padding: 0;
    margin-bottom: 32px;
  }
  
  ul.multi-container label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    ul.multi-container input {
      margin: 0;
      margin-right: 1rem;
      cursor: pointer;
    }

.hs-input{
  width: 100%;
  max-width: none;
}

  .hs-fieldtype-checkbox {
    margin: 1rem 0;
  }

  .hs-button.primary {
    
  }

  .grecaptcha-badge, .hs_recaptcha {
    display: none;
  }
  
  .hs-input:not([type=file]) {
    background-color: #fff;
  }

  .hs-error-msgs {
    font-size: 1rem;
    font-weight: normal;
    padding: 0;
    list-style: none;
    margin: 0.5rem 0;
    color: #ff4646;
  }

  .hs-form-field {
    margin-bottom: 32px;
  }
`

const StyledImage = styled.div`
  position: absolute;
  top: calc(var(--section-spacing) * -1);
  right: 0;
  z-index: -1;
`

const StyledForm = styled.div`
  max-width: 590px;
  margin: 0 auto;
`

type HubspotFormProps = {
  portalId: number,
  formId: string,
  heading?: React.ReactNode,
  image?: GatsbyImageProps,
  anchor?: string,
}

export default ({ portalId, formId, heading = null, image = null, anchor = "" }: HubspotFormProps) => {
  const [showForm, setShowForm] = useState(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "400px"
  })

  useEffect(() => {
    if (inView) {
      setShowForm(true)
    }
  }, [inView])


  return (
    <Container containerProps={{
      id: anchor,
      style: { position: "relative" }
    }}>
      <StyledForm ref={ref}>

        {heading &&
        <div style={{ textAlign: "center", marginBottom: 64 }}>{heading}</div>
        }
        {showForm &&
        <React.Fragment>
          <HubspotForm
            portalId={portalId}
            formId={formId}
            loading={<h3>Loading...</h3>}
            cssRequired={FormStyles}
          />
          <StyledRecaptcha>
            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy
            Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </StyledRecaptcha>
        </React.Fragment>
        }
        {!showForm && <StyledRecaptcha>Loading...</StyledRecaptcha>}
      </StyledForm>
    </Container>
  )
}
