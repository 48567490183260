import * as React from "react"
import HubspotForm from "../../components/sections/HubspotForm"
import { graphql } from "gatsby"
import { normalizeImageProps } from "../../utils/image"
import parse from "html-react-parser"

export default ({primary}) => {
  const { portal_id, form_id, image, heading, anchor } = primary

  return <HubspotForm 
    heading={parse(heading?.html)} 
    formId={form_id} 
    portalId={portal_id} 
    image={normalizeImageProps(image)}
    anchor={anchor}
  />
}

export const query = graphql`
    fragment HubspotForm on PrismicPageDataBodyHubspotForm {
        slice_type
        slice_label
        __typename 
        primary{
            portal_id
            form_id
            heading{
                html
            }
            anchor
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 350)
            }
        }
    }
`
