import * as React from "react"
import styled from "styled-components"
import Container from "../../atoms/Container"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

interface VideoProps {
  url: string,
  small: boolean,
}

const StyledWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`

const videoId = (url: string) => {
  if (!url) {
    return null
  }

  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  var match = url.match(regExp)

  if (match && match[1].length == 11) {
    return match[1]
  }
}

const ratioToPercent = (ratio: string) => {
  const [w, h] = ratio.split(":").map((num) => Number(num))
  return `${(h / w) * 100}%`
}

const VideoSection = (props: VideoProps) => {
  const { url, small = false } = props

  const id = videoId(url)
  const paddingBottom = ratioToPercent("16:9")

  if (!id) {
    return null
  }

  return (
    <Container small={small}>
      <StyledWrapper style={{ paddingBottom }}>
        <LiteYouTubeEmbed
          id={id}
          params={"rel=0&modestbranding=1"}
          poster={"maxresdefault"}
        />
      </StyledWrapper>
    </Container>
  )
}

export default VideoSection
