import React, { useRef } from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import { Composition, useResponsiveValue, query } from "atomic-layout"
import Button from "../../atoms/Button"
import Image from "../../organisms/Image"
import { gap } from "../../../utils/spacing"

const StyledContainer = styled(Container)`
  //overflow: hidden;
  p {
    font-size: var(--callout-text-size);
  }
`

const StyledContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: auto;
  @media ${query({from: "lg"})}{
    min-height: 540px;
  }
`

const StyledImage = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const HeroSection = ({
                       heading = null,
                       image = {},
                       buttonUrl,
                       buttonText,
                       video = {},
                       text = ""
                     }) => {
  const contentRef = useRef(null)

  const marginTop = useResponsiveValue(
    {
      md: "80px",
      lg: "2rem"
    },
    "20px"
  )

  return (
    <StyledContainer removeVerticalSpacing
                     containerProps={{ style: { marginTop, marginBottom: "var(--section-spacing)" } }}>
      <Composition
        areas={`
        image
        content
        `}
        templateCols={"1fr"}
        areasMd={`content image`}
        templateColsMd={"6fr 6fr"}
        templateColsLg={"5fr 7fr"}
        gap={gap}
      >
        {(Areas) => (
          <>
            <Areas.Content as={StyledContent}>
              <div ref={contentRef} style={{ position: "relative" }}>
                <div style={{ position: "relative" }}>
                  {heading}
                  {text}
                  {buttonUrl && buttonText &&
                  <Button to={buttonUrl?.url} target={buttonUrl?.target} primary>{buttonText}</Button>
                  }
                </div>
              </div>
            </Areas.Content>

            {image.image &&
            <Areas.Image as={StyledImage} style={{ position: "relative" }}>
              <Image
                gatsbyImageProps={image}
                direction={"right"}
              />
            </Areas.Image>
            }
          </>
        )}
      </Composition>
    </StyledContainer>
  )
}

export default HeroSection
