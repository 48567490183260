import PrismicPageDataBodyHero from './wrappers/PrismicPageDataBodyHero'
import PrismicPageDataBodyText from './wrappers/PrismicPageDataBodyText'
import PrismicPageDataBodyImageWithText from './wrappers/PrismicPageDataBodyImageWithText'
import PrismicPageDataBodyVideo from './wrappers/PrismicPageDataBodyVideo'
import PrismicPageDataBodyLogos from './wrappers/PrismicPageDataBodyLogos'
import PrismicPageDataBodyImage from './wrappers/PrismicPageDataBodyImage'
import PrismicPageDataBodyTestimonials from './wrappers/PrismicPageDataBodyTestimonials'
import PrismicPageDataBodyHubspotForm from './wrappers/PrismicPageDataBodyHubspotForm'
import PrismicPageDataBodyHeading from './wrappers/PrismicPageDataBodyHeading'
import PrismicPageDataBodyCallout from './wrappers/PrismicPageDataBodyCallout'
import PrismicPageDataBodyStats from './wrappers/PrismicPageDataBodyStats'
import PrismicPageDataBodyRoiCalculator from './wrappers/PrismicPageDataBodyRoiCalculator'
import PrismicPageDataBodyFaqs from './wrappers/PrismicPageDataBodyFaqs'
import PrismicPageDataBodyContactInfo from './wrappers/PrismicPageDataBodyContactInfo'
import PrismicPageDataBodyMaxLoanCalculator from './wrappers/PrismicPageDataBodyMaxLoanCalculator'
import PrismicPageDataBodyCalculatorWithText from './wrappers/PrismicPageDataBodyCalculatorWithText'

const slices = {
  PrismicPageDataBodyHero,
  PrismicPageDataBodyText,
  PrismicPageDataBodyImageWithText,
  PrismicPageDataBodyVideo,
  PrismicPageDataBodyLogos,
  PrismicPageDataBodyTestimonials,
  PrismicPageDataBodyImage,
  PrismicPageDataBodyHubspotForm,
  PrismicPageDataBodyHeading,
  PrismicPageDataBodyCallout,
  PrismicPageDataBodyStats,
  PrismicPageDataBodyRoiCalculator,
  PrismicPageDataBodyFaqs,
  PrismicPageDataBodyContactInfo,
  PrismicPageDataBodyMaxLoanCalculator,
  PrismicPageDataBodyCalculatorWithText,
}

export const getSlice = (sliceType) => {
  return slices?.[sliceType]
}
