import * as React from "react"
import { graphql } from "gatsby"
import ROICalculatorSection from "../../components/sections/ROICalculatorSection"

export default ({ primary }) => <ROICalculatorSection buttonUrl={primary?.button_url} />

export const query = graphql`
    fragment RoiCalculator on PrismicPageDataBodyRoiCalculator {
        slice_label
        slice_type
        __typename
        primary{
            button_url{
                url
                target
            }
        }
    }
`
