import * as React from "react"
import { graphql } from "gatsby"
import FAQSection from "../../components/sections/FAQSection"
import parse from "html-react-parser"

export default ({ primary, items }) => {
  return (
    <FAQSection 
      questions={items.map(q => ({ title: q.question, text: parse(q.answer?.html) }))}
      link={primary?.faq_page?.url}
    />
  )
}

export const query = graphql`
    fragment Faqs on PrismicPageDataBodyFaqs {
        slice_label
        slice_type
        __typename
        primary{
            faq_page{
                url
            }
        }
        items{
            question
            answer{
                html
            }
        }
    }
`
