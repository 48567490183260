export const normalizeImageProps = (image) => {
  // if(Array.isArray(image)){
  //   return image.map(i => i?.localFile?.childImageSharp || i)
  // }
  return {
    // ...image,
    image: image?.gatsbyImageData,
    alt: image?.alt ? image.alt : "",
  }
} 
