import { Composition, query } from "atomic-layout"
import * as React from "react"
import { gap } from "../../../utils/spacing"
import Container from "../../atoms/Container"
import styled from "styled-components"

export type StatsSection = {
  items?: StatItemProps[],
  mutedColors?: boolean,
}

export type StatItemProps = {
  heading?: React.ReactNode,
  text?: string,
}

const StyledStat = styled(Container)`
  text-align: center;

  h2 {
    margin-bottom: 32px;
  }
`

const StyledStatItem = styled.div`
  text-align: center;

  .heading p {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 54px;
    line-height: 110%;
    color: ${({mutedColors}) => mutedColors ? "#0076A8" : "#003057"};
    @media ${query({ from: "lg" })} {
      font-size: 75.76px;
    }
  }

  &:nth-child(even) {
    .heading p {
      color: ${({mutedColors, theme}) => mutedColors ? "#003057" : theme.secondaryColor};
    }
  }

  p {
    margin-top: 0;
  }

`

const StatsSection = ({
                        items,
                        mutedColors = false
                      }: StatsSection) => {

  const templateColProps = {
    templateCols: "1fr",
    templateColsMd: `repeat(3, minmax(0, 1fr))`
  }

  return (
    <StyledStat>
      <Composition
        {...templateColProps}
        gapCol={gap}
        gapRow={`calc(${gap} * 2)`}
      >
        {items.map((item, index) => {
          const { heading = "", text = "" } = item

          return (
            <StyledStatItem mutedColors={mutedColors} key={heading.toString() + index.toString()}>
              <div className={"heading"}>{heading}</div>
              <p>{text}</p>
            </StyledStatItem>
          )
        })}
      </Composition>
    </StyledStat>
  )
}

export default StatsSection
