import { Composition, useResponsiveValue } from "atomic-layout"
import * as React from "react"
import Container from "../../atoms/Container"
import CalculatorWithText, { CalculatorWithTxtProps } from "../../organisms/CalculatorWithText"
import styled from "styled-components"

const StyledContainer = styled(Container)`
  //overflow: hidden;
  p {
    font-size: var(--callout-text-size);
  }
`

const CalculatorWithTextSection = ({
                                     buttonText, 
                                     buttonUrl, 
                                     heading, 
                                     text, 
                                     calcPosition = "Right",
                              }: CalculatorWithTxtProps) => {
  
  const marginTop = useResponsiveValue(
    {
      md: "80px",
      lg: "2rem"
    },
    "20px"
  )
  
  return (
    <StyledContainer removeVerticalSpacing
                     containerProps={{ style: { marginTop, marginBottom: "var(--section-spacing)" } }}>
      <Composition
        templateColProps={{
          templateCols: "1fr",
          templateColsMd: `repeat(1, minmax(0, 1fr))`
        }}
        gapRow={"calc(var(--section-spacing) * 0.75)"}
        gapCol={38}
      >
        <CalculatorWithText
          text={text}
          heading={heading}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
          calcPosition={calcPosition}
        />
      </Composition>
    </StyledContainer>
  )
}

export default CalculatorWithTextSection
