import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { ImageWithTextProps } from "../../components/organisms/ImageWithText"
import ImageWithTextSection from "../../components/sections/ImageWithTextSection"
import { normalizeImageProps } from "../../utils/image"

export default ({ primary, items }) => {
  const { cols, background_color } = primary

  const imageWithTextItems = items.map((item): ImageWithTextProps => {
    const { image, video, button_text, button_url, heading, image_position, text, text_alignment } = item
    return {
      buttonText: button_text,
      buttonUrl: button_url,
      video,
      image: normalizeImageProps(image),
      text: parse(text?.html),
      heading: heading?.text && parse(heading?.html),
      imagePosition: image_position,
      textAlignment: text_alignment,
    }
  })

  return (
    <ImageWithTextSection
      items={imageWithTextItems}
      backgroundColor={background_color}
      columns={cols.replace(" Column", "")}
    />
  )
}

export const query = graphql`
    fragment ImageWithText on PrismicPageDataBodyImageWithText {
        slice_label
        slice_type
        __typename
        id
        primary{
            cols
            background_color
        }
        items {
            button_text
            button_url {
                url
                target
            }
            video {
                url
                target
            }
            heading {
                html
                text
            }
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 1200)
            }
            image_position
            image_text
            text {
                html
            }
            text_alignment
        }
    }
`
