import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Footer from "../components/organisms/Footer"
import { normalizeImageProps } from "../utils/image"

export default ({ disclaimer, removeLinks = false }) => {
  return (
    <StaticQuery
      query={`${query}`}
      render={({ prismicFooter }) => {
        const { data = {} } = prismicFooter
        const {
          logo = {},
          body = {}
        } = data

        return <Footer
          removeLinks={removeLinks}
          disclaimer={disclaimer}
          columns={body.map(col => ({
            heading: col.primary.heading,
            links: col.items
          }))}
          logo={normalizeImageProps(logo)}
        />
      }}
    />
  )
}

export const query = graphql`
    query footerQuery {
        prismicFooter {
            data {
                logo {
                    gatsbyImageData(layout: CONSTRAINED, width: 285)
                }
                body {
                    ... on PrismicFooterDataBodyColumn {
                        id
                        slice_label
                        slice_type
                        primary {
                            heading
                        }
                        items {
                            label
                            link {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
