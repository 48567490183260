import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import Container from "../../atoms/Container"

const ImageSection = ({image, width = 'full'}) => (
  <Container fluid={width === 'full'} small={width === 'small'}>
    <GatsbyImage {...image} />
  </Container>
)

export default ImageSection
