import { Composition, query } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import { CSSProperties, useRef } from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button/index"
import Image from "../Image"
import { LinkProps } from "../../../types/types"

export interface ImageWithTextProps {
  buttonUrl?: LinkProps,
  buttonText?: string,
  image?: GatsbyImageProps,
  video?: LinkProps,
  text?: React.ReactNode,
  heading?: React.ReactNode,
  imagePosition?: "Top" | "Left" | "Right",
  className?: string,
  style?: CSSProperties,
  logo?: GatsbyImageProps,
  showOffset?: boolean,
  textAlignment?: "left" | "center"
}

const areas = `
image
text
`

const StyledLogo = styled(GatsbyImage)`
  width: 100%;
  max-width: 50%;
  margin-bottom: 2rem;
  height: 10vw;
  max-height: 70px;

  @media ${query({ from: "lg" })} {
    max-width: 50%;
  }
`

const StyledText = styled.div`
  margin-bottom: 0;
  text-align: ${({textAlignment}) => textAlignment};
  @media ${query({from: "xl"})}{
    margin-bottom: var(--gutter);
  }

  *:first-child {
    margin-top: 0;
  }
`

const StyledImage = styled(Image)`

  @media ${query({ from: "lg" })} {
    margin-bottom: 0;
  }
`

const StyledHeadingLink = styled(Button)`
  color: var(--body-text-color);
`

const StyledVideo = styled.video`
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`

const ImageWithText = (
  {
    className = "",
    buttonUrl = {},
    buttonText = "",
    image = null,
    video = null,
    text = null,
    heading = null,
    imagePosition = "Top",
    style = {},
    logo = null,
    showOffset = true,
    textAlignment = "left",
  }: ImageWithTextProps
) => {
  const imageRef = useRef(null)

  let lgProps = {
    areasLg: `
            image
            text
        `,
    templateColsLg: `1fr`,
    templateRows: `auto 1fr`,
  }

  switch (imagePosition) {
    case "Right":
      lgProps = {
        areasLg: `endGap text gap image`,
        templateColsLg: `1fr 4fr 1fr 6fr`
      }
      break
    case "Left":
      lgProps = {
        areasLg: `image gap text endGap`,
        templateColsLg: `6fr 1fr 4fr 1fr`
      }
      break
  }

  const imageComponent = image?.image && <StyledImage
    showOffset={imagePosition !== "Top"}
    direction={imagePosition === "Top" ? "left" : imagePosition.toLowerCase()}
    gatsbyImageProps={image}
  />

  return (
    <Composition
      areas={areas}
      {...lgProps}
      gap={image?.image && gap}
      alignItems={imagePosition !== "Top" ? "center" : "flex-start"}
      className={className}
      style={style}
    >
      {(Areas) => (
        <>
          {image?.image &&
          <Areas.Image>
            {!buttonUrl?.url && imageComponent}

            {buttonUrl?.url &&
            <Button to={buttonUrl?.url} target={buttonUrl?.target}>{imageComponent}</Button>
            }
          </Areas.Image>
          }
          {video?.url &&
          <Areas.Image>
            <StyledVideo className="fullscreen"
                   autoPlay
                   muted
                   loop
                   style={{ objectFit: "cover", width: "100%", height: "100%" }}
            >
              <source src={video?.url} type="video/mp4;" />
            </StyledVideo>
          </Areas.Image>
          }
          <Areas.Gap />

          <Areas.Text style={{ position: "relative" }}>
            {heading}
            <StyledText textAlignment={textAlignment}>
              {text}
            </StyledText>
            {buttonUrl && buttonText &&
            <Button to={buttonUrl?.url} target={buttonUrl?.target} secondary>{buttonText}</Button>
            }
          </Areas.Text>
          
          <Areas.EndGap />
        </>
      )}
    </Composition>
  )
}

export default ImageWithText
