import * as React from "react"
import Container from "../../atoms/Container"
import ExpandableList, { ExpandableListItemProps } from "../../molecules/ExpandableList"
import Button from "../../atoms/Button"
import styled from "styled-components"
import {TemplateContext} from "../../../layout"

interface FAQSectionProps extends ExpandableListItemProps {
  questions: ExpandableListItemProps[],
  link?: string
}

const StyledButton = styled.div`
  text-align: center;
  margin-top: 64px;
`

const FAQSection = ({ questions = [], link = null }: FAQSectionProps) => {
  const isIframe = React.useContext(TemplateContext) === 'page-remove-header-footer'
  
  return (
    <Container small>
      <div style={{ textAlign: "center" }}>
        <a id={"faq"} className={"anchor"} />
        <h2>Frequently asked questions</h2>
      </div>
      <ExpandableList items={questions} />
      {link &&
        <StyledButton>
          <Button to={link} target={isIframe ? '_blank' : '_self'} dark>View All Questions</Button>
        </StyledButton>
      }
    </Container>
  )
}

export default FAQSection
