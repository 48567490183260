import { Composition, query } from "atomic-layout"
import * as React from "react"
import { CSSProperties } from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button/index"
import Image from "../Image"
import { LinkProps } from "../../../types/types"
import MaxLoan from "../MaxLoan"

export interface CalculatorWithTxtProps {
  buttonUrl?: LinkProps,
  buttonText?: string,
  text?: React.ReactNode,
  heading?: React.ReactNode,
  calcPosition?: "Left" | "Right",
  className?: string,
  style?: CSSProperties,
}

const areas = `
text
calc
`

const StyledText = styled.div`
  margin-bottom: 0;
  @media ${query({ from: "xl" })} {
    margin-bottom: var(--gutter);
  }

  *:first-child {
    margin-top: 0;
  }
`

const StyledImage = styled(Image)`

  @media ${query({ from: "lg" })} {
    margin-bottom: 0;
  }
`
const CalculatorWithText = (
  {
    className = "",
    buttonUrl = {},
    buttonText = "",
    text = null,
    heading = null,
    calcPosition = "Right",
    style = {}
  }: CalculatorWithTxtProps
) => {

  let lgProps = {
    areasLg: `text gap calc`,
    templateColsLg: `6fr 1fr 5fr`
  }

  if (calcPosition === "Left") {
    lgProps = {
      areasLg: `calc gap text`,
      templateColsLg: `5fr 1fr 6fr`
    }
  }

  return (
    <Composition
      areas={areas}
      {...lgProps}
      gap={gap}
      alignItems={"center"}
      className={className}
      style={style}
    >
      {(Areas) => (
        <>
          <Areas.Calc>
            <MaxLoan />
          </Areas.Calc>

          <Areas.Gap />

          <Areas.Text style={{ position: "relative" }}>
            {heading}
            <StyledText>
              {text}
            </StyledText>
            {buttonUrl && buttonText &&
              <Button to={buttonUrl?.url} target={buttonUrl?.target} secondary>{buttonText}</Button>
            }
          </Areas.Text>

          <Areas.EndGap />
        </>
      )}
    </Composition>
  )
}

export default CalculatorWithText
