import { Composition, query } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Container from "../../atoms/Container"
import { gap } from "../../../utils/spacing"
import Link from "../../atoms/Link"
import leaf from "../../../../static/images/maple-leaf.svg"

type ColumnProps = {
  heading: string,
  links: LinkProps[],
}

type LinkProps = {
  label: string,
  link: {
    url: string,
  }
}

interface FooterProps {
  columns?: ColumnProps[],
  disclaimer: React.ReactNode,
  logo?: GatsbyImageProps,
  removeLinks?: boolean,
}

const StyledLogo = styled.div`
  display: block;
  text-align: center;
  
  margin-bottom: 40px;
  @media ${query({from: "lg"})}{
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .logo {
    width: 285px;
    height: auto;
  }
  
  h4{
    font-size: 21px;
    font-style: normal;
    font-weight: normal;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #003057;
    margin-top: 22px;
  }
`

const StyledCopyright = styled.div`
  margin-top: 40px;
  font-size: 12px;
  display: flex;
`

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  @media ${query({from: "lg"})}{
      flex-direction: row;
  }
  border-top: 1px solid #C69214;
  border-bottom: 1px solid #C69214;
  padding: 40px 0;
  margin-bottom: var(--paragraph-margin-block-end);
`

const StyledColumn = styled.div`
  
  @media ${query({from: "md"})}{
    min-width: 175px;  
  }

  strong {
    line-height: 145%;
    margin-bottom: 10px;
    display: block;
  }

  ul {
    margin: 0;
    padding-left: 0;

    li {
      list-style: none;
      font-size: 18px;
      line-height: 145%;
      letter-spacing: -0.02em;
      margin-bottom: 10px;
    }
  }
`

const StyledDisclaimer = styled.div`
  p:first-child{
    margin-top: 0;
  }
  p, ul, li, a{
    font-size: 13.5px;
    line-height: 150%;
    color: #4E4B48;  
  }
`

const StyledComposition = styled(Composition)`
  flex: 1;
  text-align: center;
  @media ${query({from: "sm"})}{
      text-align: left;
  }
  @media ${query({from: "lg"})}{
    margin-left: auto;
  }
  
`

export default function Footer(props: FooterProps) {
  const { columns = [], disclaimer = null, logo = null, removeLinks = false } = props

  return (
    <Container as={"footer"} >
      <StyledFooter>
        {logo?.image &&
        <StyledLogo>
          {<GatsbyImage {...logo} />}
          <h4>Call: <a href={'tel:+18559737483'}>(855) 97DRIVE</a></h4>
        </StyledLogo>
        }

        {removeLinks &&
          <StyledDisclaimer>
            {disclaimer}
          </StyledDisclaimer>
        }
        {!removeLinks &&
          <StyledComposition
            templateCols={`1fr`}
            templateColsSm={`repeat(3, 1fr)`}
            templateColsMd={`repeat(3, fit-content(25%))`}
            gap={gap}
            justifyContent={'center'}
            justifyContentLg={'right'}
          >
            {columns.map(({ heading, links }, index) => <StyledColumn key={"footercol" + index.toString()}>
                <strong>{heading}</strong>
                <ul>
                  {links.map(({ link, label }, linkIndex) => <li
                    key={'link' + index.toString() + "-" + linkIndex.toString()}><Link to={link.url}>{label}</Link></li>)}
                </ul>
              </StyledColumn>
            )}
          </StyledComposition>
        }
      </StyledFooter>
      {!removeLinks &&
        <StyledDisclaimer>
          {disclaimer}
        </StyledDisclaimer>
      }
      {!removeLinks && 
        <StyledCopyright>
          <div>© Copyright Driver Capital {(new Date()).getFullYear()}</div>
          <div style={{marginLeft: "auto", display: "flex", alignItems: "center", whiteSpace: "nowrap"}}>Proudly Canadian <img src={leaf} alt={'maple leaf'} style={{marginLeft: 7}} height={18}/></div>
        </StyledCopyright>
      }
    </Container>
  )
}
