import * as React from "react"
import styled from "styled-components"
import { Composition, query } from "atomic-layout"

export interface ContactInfoProps {
  heading?: string,
  hours?: React.ReactNode,
  phone?: string,
  phoneAlt?: string,
  email?: string,
  topRight?: React.ReactNode,
  bottomRight?: React.ReactNode, 
}

const StyledWrapper = styled.div`
  background: #F9F4E8;
  border: 1px solid #EFE0BC;
  box-sizing: border-box;
  border-radius: 22px;
  padding: 40px 20px;
  @media ${query({from: "md"})}{
    padding: 50px;
  }
  
  h3{
    line-height: 130%;
    letter-spacing: -0.03em;
    color: #C69214;
    margin-bottom: 0;
  }
  
  .large-blue-text{
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.04em;
    color: #003057;
    font-weight: bold;
    
    @media ${query({from: "md"})}{
      font-size: 42.63px;
    }
    a{
      text-decoration: underline;
    }
  }
  
  .small-blue-text{
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #003057;
    font-weight: bold;

    @media ${query({from: "md"})}{
      font-size: 23.99px;
    }
  }
`

const StyledInfo = styled.div`
    margin-bottom: 64px;
    @media ${query({from: "lg"})}{
        margin-bottom: 0;
    }
`

const ContactInfo = ({ heading = "", hours = null, phone = "", phoneAlt = "", email = "", topRight = null, bottomRight = null }: ContactInfoProps) => (
  <StyledWrapper>
    <Composition
      areas={`
        info 
        text
      `}
      areasLg={`info text`}
      templateCols={`1fr 1fr`}
      gapCol={170}
    >
      {(Areas) => (
        <>
          <Areas.Info as={StyledInfo}>
            <div style={{marginBottom: 40}}>
              <h3>Call us</h3>
              <div className={'large-blue-text'}>{phone}</div>
              <div className={'small-blue-text'}>{phoneAlt}</div>
            </div>
            
            <div>
              <h3>Email us</h3>
              <div className={'large-blue-text'}><a href={`mailto:${email}`}>{email}</a></div>
            </div>
          </Areas.Info>
          <Areas.Text>
            <div style={{marginBottom: 40}}>{topRight}</div>
            <div>{bottomRight}</div>
          </Areas.Text>
        </>
    )}
    </Composition>
  </StyledWrapper>
)

export default ContactInfo
