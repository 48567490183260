import * as React from "react"
import ContactInfoSection from "../../components/sections/ContactInfoSection"
import { graphql } from "gatsby"
import parse from "html-react-parser"

export default ({ primary }) => {
  const { plain_heading, hours, phone, phone_alt, email, top_right_info, bottom_right_info } = primary
  return (
    <ContactInfoSection
      heading={plain_heading}
      hours={parse(hours?.html)}
      phone={phone}
      phoneAlt={phone_alt}
      email={email}
      topRight={parse(top_right_info?.html)}
      bottomRight={parse(bottom_right_info?.html)}
    />
  )
}

export const query = graphql`
    fragment ContactInfo on PrismicPageDataBodyContactInfo
    {
        slice_label
        slice_type
        __typename
        primary
        {
            plain_heading
            hours
            {
                html
            }
            phone
            phone_alt
            email
            top_right_info
            {
                html
            }
            bottom_right_info
            {
                html
            }
        }
    }
`
