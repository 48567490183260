import * as React from "react"
import styled from "styled-components"
import Question from "./question"

export interface ExpandableListProps {
  items?: ExpandableListItemProps[],
}

export interface ExpandableListItemProps {
  title?: string,
  text?: React.ReactNode
}

const StyledList = styled.div`
  margin: 0;
`

const ExpandableList = (({ items = [] }: ExpandableListProps) => {
  return <StyledList>
    {items.map(({title = "", text}, index) => 
      <Question key={title + index.toString()} title={title} text={text} index={index} />
    )}
  </StyledList>
})


export default ExpandableList
